<template>
  <a href="#" class="whatsapp-btn">
    <img src="@/assets/images/whatsapp.png" alt="Whatsapp Geor" width="32" />
    <span class="ml-2">Pedir ayuda a soporte</span>
  </a>
</template>

<script>
export default {
  name: "BtnWhatsapp",
};
</script>
