<template>
  <v-main class="bg-body-color main">
    <div class="wrapper__auth-new">
      <div class="auth__logo">
        <img src="@/assets/images/logo.svg" alt="Geor, iniciar sesión" />
      </div>
      <div class="auth__box">
        <h6 class="auth__box-h6">Código de verificación</h6>
        <p v-if="form.type === 'email'" class="auth__box-p">
          Te hemos enviado un código al correo electrónico
        </p>
        <p v-else class="auth__box-p">Te hemos enviado un SMS con el código a</p>
        <ValidationObserver v-slot="{ invalid }" tag="div" ref="formVerifyCode">
          <h3 v-if="form.type === 'email'" class="text-center mb-6">
            {{ form.email }}
          </h3>
          <h3 v-else class="text-center mb-6">{{ form.prefix }} {{ form.phone }}</h3>
          <form @submit.prevent="onSubmit" autocomplete="off">
            <ValidationProvider
              name="código"
              vid="code"
              rules="required|digits:6"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="form.code"
                label="Código"
                :error-messages="errors"
                v-mask="'######'"
                placeholder="Ingrese el código de verificación..."
              ></v-text-field>
            </ValidationProvider>
            <p class="text-center">
              <v-btn text :disabled="loading" @click="onResendCode" rounded color="primary"
                >Reenviar código</v-btn
              >
            </p>
            <div class="text-center">
              <v-btn
                :disabled="invalid || loading"
                :loading="loading"
                color="primary"
                depressed
                rounded
                class="v-btn-pad-x-large"
                type="submit"
                >VALIDAR</v-btn
              >
              <br />
              <br />
              <v-btn
                depressed
                rounded
                :disabled="loading"
                :to="{ name: 'Register' }"
                class="v-btn-pad-x-large"
                >ATRÁS</v-btn
              >
            </div>
          </form>
        </ValidationObserver>
      </div>
      <BtnWhatsapp />
    </div>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import BtnWhatsapp from "../components/BtnWhatsapp.vue";

export default {
  components: {
    BtnWhatsapp,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_URL_WEB,
      form: {
        country_id: null,
        phone: null,
        code: null,
        prefix: null,
        type: "phone",
        email: "",
      },
    };
  },
  mounted() {
    const query = { ...this.$route.query };
    if (query.type === "email") {
      this.form.type = "email";
      if (!query.country_id || !query.email) {
        this.$toast.warning("No se encontró el correo electrónico.");
        this.$router.push({ name: "RegisterEMail" });
        return;
      }
      this.form.country_id = query.country_id;
      this.form.email = query.email;
    } else {
      this.form.type = "phone";
      if (!query.country_id || !query.phone || !query.prefix) {
        this.$toast.warning("No se encontró el número de teléfono.");
        this.$router.push({ name: "Register" });
        return;
      }
      this.form.country_id = query.country_id;
      this.form.phone = query.phone;
      this.form.prefix = query.prefix;
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
    }),
  },
  methods: {
    onResendCode() {
      const payload = {
        country_id: this.form.country_id,
      };
      if (this.form.type === "email") {
        payload.email = this.form.email;
      } else {
        payload.phone = this.form.phone;
      }
      this.$axios.post("auth/register/resend-code", payload).then((response) => {
        const { data } = response;
        this.$toast.success(data.message);
      });
    },
    onSubmit() {
      const payload = {};
      payload.code = this.form.code;
      if (this.form.type === "email") {
        payload.email = this.form.email;
        payload.password = this.form.password;
      } else {
        payload.country_id = this.form.country_id;
        payload.phone = this.form.phone;
      }
      this.$axios
        .post("auth/verify", payload)
        .then((response) => {
          const { data } = response;
          const token = this.$auth.generateToken(data.data.token_type, data.data.access_token);
          this.$auth.saveUser(data.data.user);
          this.$store.dispatch("ON_LOGIN", token);
          this.$router.push({ name: "Dashboard" });
        })
        .catch((error) => this.showErrors(error, "formVerifyCode"));
    },
  },
};
</script>
